import React from 'react'

import Layout from '@/components/Layout'
import Card from '@/components/Card'
import Message from '@/components/Message'

const Programmas = () => {
  return (
    <Layout
      pageTitle="Programma's"
      heroTitle="Trainingen"
      heroSubTitle=""
      heroTagLine="Van wit tot zwart"
      heroImage="">

      <section className="section pt-5 pt-md-0">
        <div className="container">
          <div className="row">
            <Message>
              <p>
                Volg onderstaande links naar de gedetailleerde programma's voor juniores en seniores.
                Er is een vast aantal trainingen en de toestemming van de hoofdlesgever nodig vooraleer in aanmerking te komen voor een graadverhoging.
              </p>
            </Message>
          </div>

          <div className="row row-cols-1 row-cols-md-2">
            <Card title="Juniores" link="/programmas/juniores" />

            <Card title="Seniores" link="/programmas/seniores" />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Programmas
